import React from "react"
import styled from "@emotion/styled"

const H1 = styled.h1`
  font-weight: 900;
  font-size: 28px;
  margin-top: 48px;
  margin-bottom: 32px;
  color: #1f2933;
  line-height: 140%;

  @media screen and (min-width: 1050px) {
    font-size: 36px;
    font-weight: 800;
    margin: 96px 0 64px;
  }
`

const Title = ({ content }) => <H1>{content}</H1>

export default Title
