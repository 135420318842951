import React from "react"
import styled from "@emotion/styled"
import Seo from "../components/seo"
import ServiceCard from "../components/serviceCard"
import BreadCrumbs from "../components/breadCrumbs"
import Title from "../components/title"
import Text from "../components/text"

const Container = styled.div`
  margin: 16px 20px 128px;
  @media screen and (min-width: 1050px) {
    margin: 24px 0 192px;
  }
`
const CardContainer = styled.div`
  margin-top: 32px;
  @media screen and (min-width: 1050px) {
    display: flex;
    margin-top: 96px;
  }
`
const Spacer = styled.div`
  height: 32px;
`

const ServicesPage = ({ location }) => (
  <>
    <Seo
      title="Servizi e consulenze"
      description="Assistenza e consulenza in tutte le aree che interessano la vita di un impresa o di un lavoratore autonomo."
    />
    <Container>
      <BreadCrumbs location={location} />
      <Title content="I servizi dello studio" />
      <Text style={{ margin: 0, padding: 0 }}>
        Assistenza e consulenza in tutte le aree che interessano la vita di un
        impresa o di un lavoratore autonomo.
      </Text>
      <Text>
        I nostri servizi di consulenza si riferiscono a tre macro aeree:
      </Text>
      <CardContainer>
        <ServiceCard color="blue" title="Area Fiscale">
          <Text>
            Assistenza nelle verifiche fiscali e nel contenzioso tributario.
          </Text>
          <Text>
            Assistenza fiscale continuativa per società e persone fisiche.
          </Text>
          <Text>Consulenza amministrativa e contabile.</Text>
          <Text>
            Assistenza fiscale nell’ambito di operazioni straordinarie.
          </Text>
        </ServiceCard>
        <Spacer />
        <ServiceCard color="grey" title="Area Aziendale">
          <Text>Valutazioni di aziende, rami d’azienda.</Text>
          <Text>Assistenza per la predisposizione di business plan.</Text>
          <Text>Assistenza nella risoluzione di crisi d’impresa.</Text>
          <Text>Due diligence societarie, legali e fiscali.</Text>
        </ServiceCard>
        <Spacer />
        <ServiceCard color="red" title="Area Legale & Societaria">
          <Text>Consulenza societaria e commerciale.</Text>
          <Text>
            Consulenza nelle ristrutturazioni e nelle riorganizzazioni
            societarie.
          </Text>
          <Text>Consulenza nei passaggi generazionali.</Text>
        </ServiceCard>
      </CardContainer>
    </Container>
  </>
)

export default ServicesPage
