import styled from "@emotion/styled"

const Text = styled.p`
  color: #323f4b;
  @media screen and (min-width: 1050px) {
    font-size: 16px;
  }
`

export default Text
