import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Container = styled.span`
  color: #616e7c;
  font-weight: 600;
  font-size: 13px;
  & a {
    color: #616e7c;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 300ms ease;
    &:hover {
      color: #1f2933;
    }
  }
  @media screen and (min-width: 1050px) {
    font-size: 12px;
  }
`

const BreadCrumbs = ({ location }) => {
  const [path, setPath] = useState("")

  useEffect(() => {
    setPath(location.pathname.slice(1))
  }, [location])

  return (
    <Container>
      <Link to="/">Home </Link>
      &gt;
      <Link to={`/${path}`}> {path}</Link>
    </Container>
  )
}

export default BreadCrumbs
